import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a className="sel" href="/kontakt/">
                    Kontakt
                  </a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/kontakt/" className="sel">
              {" "}
              &gt; Kontakt
            </a>
          </div>
          <div
            id="page_content"
            style={{
              "line-height": "24px"
            }}
          >
            Našich zákazníkov si vždy radi vypočujeme. Dajte nám vedieť svoje
            pripomienky, otázky a celkovo spätnú väzbu k našim produktom.
            <br />
            <br />
            JNTL Consumer Health (Slovakia) s.r.o.
            <br />
            Karadžičova 8/A,
            <br />
            Bratislava, 821 08
            <br />
            <br />
            Tel.: 00 800 555 22 000 (bezplatné volanie, dostupné len z pevnej
            linky) v pracovných dňoch PO-PIA, 9:00-13:00 hod
            <br />
            <br />
            Kliknutím{" "}
            <a
              href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
              target="_blank"
            >
              sem
            </a>{" "}
            nás môžete kontaktovať.
            <br />
            <br />
            Ak chcete nahlásiť nežiaduci účinok alebo sťažnosť na kvalitu produktu OLYNTH®, kliknutím{" "}
            <a
              href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800002r1rGQ"
              target="_blank"
            >
              sem
            </a>{" "}
            nás môžete kontaktovať, aby sme sa Vám mohli venovať osobne.
            <br />
            <br />
            Bohužiaľ nie je možné, aby sme poskytovali radu v osobných
            zdravotných záležitostiach. S otázkami tohto typu sa prosím obráťte
            na svojho lekára alebo lekárnika. Vami poskytnuté osobné údaje budú
            spracované v súlade s našimi Zásadami ochrany osobných údajov a to
            za účelom vybavenia Vašej požiadavky. Vaše osobné údaje bude
            spracovávať spoločnosť JNTL Consumer Health (Slovakia) s.r.o., ako
            prevádzkovateľ a tiež spoločnosť Sitel Europe Limited, identifikačné
            číslo GB 672 54 34 27, so sídlom Building 600, Leavesden Business
            Park, Hercules Way, Watford, Veľká Británia a Sitel Bulgaria EOOD,
            identifikačné číslo BG 175054504, so sídlom Blvc. N. Vapzarov 55,
            GB-1407 Sofia, Bulharsko, ako prevádzkovateľom určení
            sprostredkovatelia. Všetky osobné údaje sú poskytované dobrovoľne.
          </div>
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
